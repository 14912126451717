header {
  text-align: center;
  height: 20vh;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.storage-container {
  background-color: #ffffff;
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.sign-out-button {
  background-color: #334556;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-container {
  text-align: center;
  margin-top: 20px;
}

.alert-container {
  margin-top: 20px;
}
